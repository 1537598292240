import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { useEffect } from "react"
import axios from "axios"
import Cookies from "universal-cookie"
import { isLoggedIn, getUser } from "../../../services/auth"
import config from "../../../config/config"

import Layout from "../../../templates/Page"
import Header from "../../ContentBuilder/Header"
import SwiperList from "../../ContentBuilder/SwiperList"
import Subscribe from "../../Footer/subscribe"
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap"
import SelectionRelatedItems from "./SelectionRelatedItems"
import ShowMoreText from "react-show-more-text"
import CreateBoardModal from "../../ContentBuilder/Modal/CreateBoardModal"
import { getRecentPages, setRecentPages } from "../../../services/account-services"
import RecentViews from "../../RecentViews"

const StyleDetail = ({ data }) => {
  var [title, setTitle] = useState("")
  const [isReadmore, setReadmore] = useState(true)
  const [isViewmore, setViewmore] = useState(true)
  const [item, setItem] = useState({})
  const [globalConfig, setGlobalConfig] = useState({})
  useEffect(() => {
    if (data) {
      setItem(data.allSilverStripeDataObject.nodes[0].UndigitalSelection)
      setGlobalConfig(data.silverStripeDataObject.UndigitalGlobalConfig);
    }
  }, [JSON.stringify(data)]);
  const handleChangeTitle = e => {}
  var designers = ""
  var designersArr = []
  var designersList = item.Designers
  if (designersList !== null && designersList !== undefined) {
    designersList.forEach(function (item, index) {
      designersArr.push(item.UndigitalDesigner.name)
    })
    designers = designersArr.join(", ")
  }
  var rrp = item.costPrice
  rrp = parseFloat(Math.round(rrp * 1000) / 1000).toFixed(2)
  item.formatted_rrp =
    "$" + rrp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  var boardsObjs = null
  if (typeof item.boardsData !== "undefined") {
    var boardsData = item.boardsData
    var objects = JSON.parse(boardsData)
    boardsObjs = Object.keys(objects).map(key => objects[key])
    if (boardsObjs.length > 0 && title === "") title = boardsObjs[0].Name
    boardsObjs.forEach(function (item, index) {
      if (item.Images) {
        let images = Object.keys(item.Images).map(key => item.Images[key])
        item.Images = images
      }
    })
  }

  item.boards = boardsObjs
  const showAddBoard = () => {
    if (isLoggedIn()) {
      setShowCreateBoard(true)
    } else {
      if (typeof window !== "undefined") {
        window.location.href = "/login/"
      }
    }
  }

  const Member = getUser();
  let boards = [];
  
  if(isLoggedIn()){
    boards = Object.keys(Member.Boards).map(key => Member.Boards[key]);    
  }

  let isSaved = false
  for (const board of boards) {
    const items = JSON.parse(board.Items)
    for (const key in items) {
      if (key.includes('Selection') && items[key].ID == item.objectID) {
        isSaved = true
      }
    }
  }

  if (item && item.status === 'Active') {
    const recentPages = getRecentPages();
    setRecentPages([
      { ...item, className: 'Selection' },
      ...recentPages.filter(rp => !(rp.className == 'Selection' && rp.objectID == item.objectID))
    ])
  }

  const [showCreateBoard, setShowCreateBoard] = useState(false)
  return (
    <Layout hideHeader>
      <CreateBoardModal
        show={showCreateBoard}
        handleClose={() => setShowCreateBoard(false)}
        objectClass="Selection"
        objectID={item.objectID}
      />
      <Header isLogin />

      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/explore/style/">Style</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {item.name}
            </li>
          </ol>
        </nav>

        <div className="mt-30 mb-30 border-bottom-7px">
          <div className="row align-items-center">
            <div className="col-md">
              <h1 className="product-name-title">
                {item.name} by {designers}
              </h1>
            </div>
            <div className="col-md-auto">
              <button type="button" className="button-link">
                <div
                  className="d-flex align-items-center"
                  onClick={() => showAddBoard()}
                >
                  {isSaved ? 'Added to board' : 'Add to board'}
                  {isSaved ? (
                    <svg className="text-black ml-3" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                      <path id="Path_878" data-name="Path 878" d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z" transform="translate(21.982 6.975)" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"/>
                    </svg>
                  ) : <svg
                    focusable="false"
                    width="30px"
                    height="30px"
                    className="text-black ml-3"
                  >
                    <use xlinkHref="/img/icons.svg#icon-heart"></use>
                  </svg>}
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="wrap-section row">
          <div className="col-md-8">
            <h3 className="mb-4 border-bottom-4px">{title}</h3>
            <div className="row gutters-10">
              {item.boards && (
                <Tab.Container defaultActiveKey={`board0`}>
                  <div className="col-lg-3 mb-5 mb-lg-0">
                    <div className="style-detail-functional">
                      <Nav>
                        {item.boards.map((board, idx) => (
                          <Nav.Link
                            key={`nav-board${idx}`}
                            eventKey={`board${idx}`}
                            className={`btn btn-outline-black hover-primary btn-block btn-lg px-2`}
                            onClick={() => setTitle(board.Name)}
                          >
                            {board.Name}
                          </Nav.Link>
                        ))}
                      </Nav>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <Tab.Content>
                      {item.boards.map((board, idx) => (
                        <Tab.Pane
                          eventKey={`board${idx}`}
                          key={`boardcontent${idx}`}
                        >
                          {board.Images && (
                            <div className="board-wrapper">
                              {board.Images.map((image, index) => (
                                <div
                                  className={`board-inner grid-item`}
                                  key={`boardchild${idx}item${index}`}
                                >
                                  <Link
                                    className={`grid-item-inner`}
                                    to={image.Link}
                                  >
                                    <img src={image.ImagePath} alt="" />
                                    <div className="grid-caption">
                                      <h4>{image.Name}</h4>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          )}
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </div>
                </Tab.Container>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="mb-1 border-bottom-4px">Information</h3>

            <div className="list-group list-group-flush mb-4">
            {globalConfig.hidePricingGlobally == 0 && (
              <div className="list-group-item px-0">
                <div className="row align-items-center">
                  <div className="col">
                    <h5 className="mb-0">Price from</h5>
                  </div>
                  <div className="col-auto">
                    <p className="h4">{item.formatted_rrp}</p>
                  </div>
                </div>
              </div>
              )}
              <div className="list-group-item px-0">
                <div className="row">
                  <div className="col">
                    <h5 className="mb-0">Designer</h5>
                  </div>
                  <div className="col-auto">
                    <p>{designers}</p>
                  </div>
                </div>
              </div>
              <div className="list-group-item px-0">
                <h5 className="mb-0">About</h5>
                {item.about && (
                  <ShowMoreText
                    line={1}
                    more="Read more"
                    less="Read less"
                    className="content-wrap"
                    anchorClass="button-link d-block mt-1 text-right"
                    expanded={false}
                    width={0}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.about }} />
                  </ShowMoreText>
                )}
              </div>
            </div>
            {item.Features && (
              <div className="d-flex flex-direction-column align-items-left mb-15">
                <h4 className="title-custom-design border-bottom-4px pb-2">
                  Feature
                </h4>
                <div className="mt-15">
                  <ul className="custom-list">
                    {item.Features.map((feature, idx) => (
                      <li key={`feature${idx}`}>
                        {feature.UndigitalStyleFeature.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <div className="row gutters-10 mt-30">
              <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                <button
                  type="button"
                  className="btn btn-outline-black btn-block btn-lg px-2 my-1"
                >
                  <div
                    className="d-flex align-items-middle justify-content-center"
                    onClick={() => showAddBoard()}
                  >
                    <svg
                      focusable="false"
                      width="24px"
                      height="24px"
                      className="mr-2"
                    >
                      <use xlinkHref="/img/icons.svg#icon-heart"></use>
                    </svg>
                    {isSaved ? 'Added to board' : 'Add to board'}
                  </div>
                </button>
              </div>
              <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                <a href="/contact-us/" className="btn btn-black btn-block btn-lg px-2 my-1">Enquire now</a>
              </div>
            </div>
          </div>
        </div>
        <SelectionRelatedItems />
        <RecentViews/>
      </div>
      <Subscribe></Subscribe>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
          hidePricingGlobally
        }
      }
    allSilverStripeDataObject(
      filter: {
        UndigitalSelection: {
          urlSegment: { eq: $slug }
          status: { in: ["Active", "Draft"] }
        }
      }
    ) {
      nodes {
        UndigitalSelection {
          status
          name
          boardsData
          costPrice
          urlSegment
          objectID
          about
          Designers {
            UndigitalDesigner {
              name
            }
          }
          imagePath
          Features {
            UndigitalStyleFeature {
              name
              objectID
            }
          }
          Style {
            UndigitalProductStyle {
              name
              objectID
            }
          }
        }
      }
    }
  }
`
export default StyleDetail
